<template>
  <div id="reportClassification">
    <a-page-header
      title="分类管理"
      style="padding-left: 0; margin-bottom: 10px; border-bottom: 1px solid rgb(235, 237, 240)"
    />

    <a-form layout="inline" style="display: flex">
      <a-form-item label="分类名称: ">
        <a-input style="width: 200px" placeholder="请输入" v-model="searchInfo.classifyName" />
      </a-form-item>
      <a-form-item label="状态: ">
        <a-select
          :getPopupContainer="
            (triggerNode) => {
              return triggerNode.parentNode || document.body;
            }
          "
          style="width: 200px"
          placeholder="请选择"
          v-model="searchInfo.classifyStatus"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option v-for="item in classifyStatus" :value="item.value" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="类型: ">
        <a-select
          :getPopupContainer="
            (triggerNode) => {
              return triggerNode.parentNode || document.body;
            }
          "
          style="width: 200px"
          placeholder="请选择"
          v-model="searchInfo.classifyType"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option v-for="item in reportTypeList" :value="item.commonsValue" :key="item.commonsValue">
            {{ item.commonsValueName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px">
          <a-icon type="search" />
          查询
        </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm"> 重置 </a-button>
      </span>
    </a-form>
    <div class="middle">
      <a-button
        type="primary"
        @click="showModal"
        v-if="$checkButtonPermissions($buttonPermissionsConfig.reportManagement.categoryManage.add)"
      >
        <a-icon type="plus" />添加
      </a-button>
      <a-table
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        style="margin-top: 15px"
        :pagination="false"
        :columns="columns"
        :data-source="operationData"
        bordered
      >
        <span slot="classifyStatus" slot-scope="record">
          <span v-if="record === 0">启用</span>
          <span v-if="record === 1">禁用</span>
        </span>
        <template slot="introduce" slot-scope="text, record">
          <a-tooltip placement="top">
            <template slot="title">
              <span>{{ record.introduce }}</span>
            </template>

            <div
              style="
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              "
            >
              {{ record.introduce }}
            </div>
          </a-tooltip>
        </template>

        <template slot="action" slot-scope="text, record">
          <a
            href="javascript:;"
            style="color: #1890ff"
            @click="modify(record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.reportManagement.categoryManage.edit)"
            >编辑</a
          >&nbsp;&nbsp;
          <a
            href="javascript:;"
            style="color: #1890ff"
            @click="deleteList(record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.reportManagement.categoryManage.delete)"
            >删除</a
          >
        </template>
      </a-table>
    </div>
    <div class="fotter">
      <a-pagination
        show-size-changer
        :show-total="(total) => `共 ${total} 条`"
        show-quick-jumper
        :pageSizeOptions="['20', '50', '100']"
        :defaultCurrent="pageNum"
        :defaultPageSize="pageSize"
        :total="total"
        :current="pageNum"
        :pageSize="pageSize"
        :showSizeChanger="true"
        @change="onChangePage"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    <div class="modal" v-if="maskActive">
      <div>
        <a-modal
          v-model="maskActive"
          :title="!this.form.id ? '添加分类' : '编辑分类'"
          :closable="false"
          :keyboard="false"
          :maskClosable="false"
          on-ok="onSubmit"
        >
          <template slot="footer">
            <a-button key="back" @click="handleCancel"> 取消 </a-button>
            <a-button key="submit" type="primary" @click="onSubmit"> 确认保存 </a-button>
          </template>
          <div>
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
              <a-form-model-item
                ref="name"
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 18 }"
                label="分类名称"
                prop="classifyName"
              >
                <a-input
                  v-model.trim="form.classifyName"
                  placeholder="请输入分类名称"
                  :maxLength="20"
                  @blur="
                    () => {
                      $refs.name.onFieldBlur();
                    }
                  "
                />
                <div>不超过20个文字</div>
              </a-form-model-item>
              <a-form-model-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }" label="类型" prop="classifyType">
                <a-radio-group
                  v-model="form.classifyType"
                  v-for="item in reportTypeList"
                  :key="item.commonsValue"
                  :defaultValue="0"
                >
                  <a-radio :value="item.commonsValue"> {{ item.commonsValueName }} </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }" label="状态" prop="classifyStatus">
                <a-radio-group
                  v-model="form.classifyStatus"
                  v-for="item in classifyStatus"
                  :key="item.value"
                  :defaultValue="0"
                >
                  <a-radio :value="item.value"> {{ item.label }} </a-radio>
                </a-radio-group>
              </a-form-model-item>

              <a-form-model-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }" label="简介" prop="introduce">
                <a-textarea
                  :maxLength="400"
                  v-model="form.introduce"
                  placeholder="请输入..."
                  :auto-size="{ minRows: 2, maxRows: 6 }"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      // 搜索条件
      searchInfo: {
        // 名称
        classifyName: "",
        // 状态
        classifyStatus: "",
        classifyType: "", //类型
      },
      maskActive: false, //弹出框
      typeList: [
        {
          value: 0,
          label: "驾驶舱",
        },
        {
          value: 1,
          label: "禁用",
        },
      ],
      classifyStatus: [
        {
          value: 0,
          label: "启用",
        },
        {
          value: 1,
          label: "禁用",
        },
      ],
      pageSize: 20,
      pageNum: 1,
      searchList: false, //用户没点查询 导致分页带检索条件
      form: { classifyStatus: 0, classifyType: 0 },
      rules: {
        classifyName: [
          { required: true, message: "分类名称不能为空", trigger: "blur" },
          { min: 1, max: 20, message: "不超过20个文字", trigger: "blur" },
        ],
        classifyStatus: [{ required: true, message: "请选择状态", trigger: "change" }],
        classifyType: [{ required: true, message: "请选择类型", trigger: "change" }],
        introduce: [
          // { required: true, message: "简介不能为空", trigger: "blur" },
          { min: 0, max: 400, message: "不超过400个字符", trigger: "blur" },
        ],
      },
      columns: [
        {
          title: "ID",
          dataIndex: "classifyNumber",
          width: 80,
        },
        {
          title: "分类名称",
          dataIndex: "classifyName",
          width: 150,
        },
        {
          title: "类型",
          dataIndex: "commonsValueName",
          width: 150,
        },
        {
          title: "简介",
          dataIndex: "introduce",
          scopedSlots: { customRender: "introduce" },
          width: 250,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreate",
          customRender: (text) => {
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
          width: 200,
        },

        {
          title: "状态",
          dataIndex: "classifyStatus",
          scopedSlots: { customRender: "classifyStatus" },
          width: 100,
        },
      ],
      // 操作集合
      actions: [
        this.$buttonPermissionsConfig.reportManagement.categoryManage.edit,
        this.$buttonPermissionsConfig.reportManagement.categoryManage.delete,
      ],
    };
  },
  watch: {
    reportTypeList(value) {
      value.forEach((element) => {
        element.commonsValue = Number(element.commonsValue);
      });
    },
    operationData(value) {
      value.forEach((element) => {
        this.reportTypeList.forEach((item) => {
          if (element.classifyType == item.commonsValue) {
            element.commonsValueName = item.commonsValueName;
          }
        });
      });
    },
  },
  created() {
    this.platformCollection({ commonsKey: "REPORT_TYPE" }); //报告类型下拉数据
    this.$checkTableActionIsShow(this.actions) && this.columns.push(this.$backTableAction({ width: "150px" }));
    this.journalDate({ pageNum: this.pageNum, pageSize: this.pageSize });
  },
  computed: {
    ...mapState({
      operationData: (state) => state.reportClassification.list,
      total: (state) => state.reportClassification.total,
      reportTypeList: (state) => state.allReports.reportTypeList,
    }),
  },

  mounted() {},
  methods: {
    ...mapActions({
      journalDate: "reportClassification/journalDate",
      manageInserts: "reportClassification/manageInserts",
      platformCollection: "allReports/platformCollection",
      manageUpdates: "reportClassification/manageUpdates",
      manageDeleteds: "reportClassification/manageDeleteds",
    }),

    success(message) {
      this.$message.success(message);
    },
    error(message) {
      this.$message.error(message);
    },
    warning(message) {
      this.$message.warning(message);
    },
    //编辑
    modify(arg) {
      this.maskActive = true;
      let argShadow = JSON.parse(JSON.stringify(arg));
      argShadow.classifyStatus = argShadow.classifyStatus * 1;
      argShadow.classifyType = Number(argShadow.classifyType);
      this.form = argShadow;
    },

    //添加
    showModal() {
      this.maskActive = true;
      this.form = { classifyStatus: 0, classifyType: 0 };
    },
    //提交
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.form.id) {
            this.manageInserts({
              form: this.form,
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              cb: (cb) => {
                this.success(cb);
                this.form = {};
                this.maskActive = false;
              },
              cbError: (cb) => {
                this.warning(cb);
                this.maskActive = true;
              },
            });
          } else {
            this.manageUpdates({
              form: this.form,
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              cb: (msg) => {
                this.success(msg);
                this.form = {};
                this.maskActive = false;
              },
              cbError: (cb) => {
                this.warning(cb);
                this.maskActive = true;
              },
            });
          }
        } else {
          return false;
        }
      });
    },
    //取消
    handleCancel() {
      // this.warning("已取消");
      this.maskActive = false;
    },
    //删除
    deleteList({ id }) {
      this.$confirm({
        title: "确定要删除吗?",
        content: "删除后不可恢复",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          this.manageDeleteds({
            labelId: id,
            pageNum: 1,
            pageSize: this.pageSize,
            cb: () => {
              this.success("删除成功");
            },
            cbError: (cb) => {
              this.warning(cb);
            },
          });
        },
        onCancel: () => {
          return false;
        },
      });
    },
    //查询
    search() {
      this.pageNum = 1;
      this.searchList = true;
      this.journalDate({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        classifyType: this.searchInfo.classifyType,
        classifyName: this.searchInfo.classifyName,
        classifyStatus: this.searchInfo.classifyStatus,
      });
    },
    //重置
    resetSearchForm() {
      this.searchList = false;
      this.searchInfo.classifyName = "";
      this.searchInfo.classifyStatus = "";
      this.searchInfo.classifyType=''
      this.pageNum = 1;
      this.pageSize = 20;
      this.journalDate({ pageNum: this.pageNum, pageSize: this.pageSize });
    },

    //分页
    onShowSizeChange(pageNum, pageSize) {
      this.pageNum = pageNum;
      this.pageSize = pageSize;
      if (this.searchList) {
        this.journalDate({ pageNum, pageSize, ...this.searchInfo });
      } else {
        this.journalDate({ pageNum, pageSize });
      }
    },
    onChangePage(pageNum, pageSize) {
      console.log(pageNum, pageSize);
      this.pageNum = pageNum;
      this.pageSize = pageSize;
      if (this.searchList) {
        this.journalDate({ pageNum, pageSize, ...this.searchInfo });
      } else {
        this.journalDate({ pageNum, pageSize });
      }
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;
}

.header button {
  margin-left: 20px;
}

.middle {
  margin-top: 15px;
}

.fotter {
  padding: 20px 0;
  float: right;
}

::v-deep .ant-modal-header {
  text-align: center;
  font-weight: bold;
}
::v-deep .ant-form-item {
  display: flex;
}

.btnClass {
  margin-left: 10px;
}
</style>
